<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>My Company Management</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmitSearch">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClearSearch"
        ></v-text-field>
      </v-form>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="disableEnroll" fab small class="ml-2" v-on="on" @click="openNewRepdialog">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Enroll New Person</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <ApiError v-if="!cancelRepsDialog" :errors="errors"></ApiError>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        :server-items-length="total"
        :options.sync="options"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status.name)" class="ma-2" text-color="white">{{
            item.status.description
          }}</v-chip>
        </template>
        <template v-slot:item.expirationDate="{ item }">
          <span v-if="item.expirationDate">
            {{ item.expirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span
          >
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom v-if="item.status.name != 'PRE'">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="mr-2" icon @click="openResetPasswordDialog(item)">
                <v-icon>mdi-key-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Reset Password") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status.name != 'PRE'">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="mr-2" icon @click="openCancelRepsDialog(item)">
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Cancel Registration") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status.name == 'PRE' && $sonyProgram.isCCPR()">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="ma-2" icon color="blue lighten-2" @click="onApprove(item)">
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Approve") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status.name == 'PRE' && $sonyProgram.isCCPR()">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="ma-2" icon color="red lighten-2" @click="onReject(item)">
                <v-icon>mdi-thumb-down</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Reject") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="resetPasswordDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">Are you sure you want to reset password?</v-card-title>
          <v-card-text>
            {{ itemSelected.user.username }} will be sent an email containing a temporary password, along with
            instructions to create a new one.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" @click="resetPassword" :loading="loadingResetPassword">Agree</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="cancelRepsDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline"> Are you sure you want to cancel Reps? </span>
          </v-card-title>
          <v-card-text>
            <ApiError :errors="errors"></ApiError>
            <v-row>
              <v-col cols="12">
                <p>
                  This means sending an email notification to the participant email address notifying them of the
                  cancellation.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-radio-group v-model="cancelOption">
                  <v-radio
                    :label="$i18n.translate('Cancel') + ' ' + $i18n.translate('Immediately')"
                    value="1"
                  ></v-radio>
                  <v-radio :label="$i18n.translate('Cancel') + ' ' + $i18n.translate('By Date')" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <DateTimePickerField
                  v-if="cancelOption == 2 && renderDateTimePickerField"
                  v-model="enrollRejectForm.expirationDate"
                  :label="this.$i18n.translate('Effective Date')"
                  defaultTime="23:59"
                ></DateTimePickerField>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn
              color="primary"
              @click="accountCancel"
              :disabled="cancelOption === '2' && enrollRejectForm.expirationDate == undefined"
              :loading="loadingCancelRep"
              >Agree</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="newRepdialog" max-width="750px">
        <v-form @submit.prevent="onSubmit" v-model="valid">
          <v-card>
            <v-card-title>
              <span class="headline"> New Enroll </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      name="firstName"
                      label="First Name"
                      id="firstName"
                      v-model="enrollUserForm.user.firstName"
                      :rules="rules.firstName"
                      :error-messages="$error.getValidationError(errors, 'firstName')"
                      @input="$error.clearValidationError(errors, 'firstName')"
                      class="required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      name="lastName"
                      label="Last Name"
                      id="lastName"
                      v-model="enrollUserForm.user.lastName"
                      :rules="rules.lastName"
                      :error-messages="$error.getValidationError(errors, 'lastName')"
                      @input="$error.clearValidationError(errors, 'lastName')"
                      class="required"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <EmailField
                      id="userName"
                      name="userName"
                      v-model="enrollUserForm.user.username"
                      label="Email Address"
                      :rules="rules.email"
                      :error-messages="$error.getValidationError(errors, 'email')"
                      @input="$error.clearValidationError(errors, 'email')"
                      class="required"
                    ></EmailField>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="enrollUserForm.participantType"
                      :items="filterByParticipantType"
                      item-text="name"
                      return-object
                      label="Participant Type"
                      :rules="rules.participantType"
                      :error-messages="$error.getValidationError(errors, 'participantType')"
                      @input="$error.clearValidationError(errors, 'participantType')"
                      class="required"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onSubmit" :disabled="!valid" :loading="loadingNewRep">Enroll</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="approveDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">Approve Registration?</v-card-title>
          <v-card-text>
            <p>
              The registration request for
              <b>{{ itemSelected.fullName }} </b> will be Approved. An email to <b>{{ itemSelected.email1 }} </b> will
              be sent with login instructions.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" @click="approve" :loading="loading">Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="600px">
        <v-form @submit.prevent="reject" v-model="rejectReasonValid">
          <v-card>
            <v-card-title>
              <span class="headline"> Reject Registration?</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p>
                    The registration request for
                    <b>{{ itemSelected.fullName }} </b> will be Rejected. An email to
                    <b>{{ itemSelected.email1 }}</b> will be sent with any comments you provide below.
                  </p>
                </v-col>
              </v-row>
              <v-row
                ><v-col cols="12">
                  <v-textarea
                    label="Comments"
                    name="reason"
                    id="reason"
                    v-model="rejectReason"
                    :rules="rules.reason"
                    :error-messages="$error.getValidationError(errors, 'reason')"
                    @input="$error.clearValidationError(errors, 'reason')"
                  ></v-textarea> </v-col
              ></v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="close()">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="reject" :loading="loading" :disabled="!rejectReasonValid">Reject</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import EmailField from "../../gapp-components/components/fields/EmailField.vue";
import DateTimePickerField from "../../gapp-components/components/fields/DateTimePickerField.vue";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError, DateTimePickerField, EmailField },
  name: "CprMyCompanyManagement",
  metaInfo: {
    title: "My Company Management"
  },

  data: function() {
    return {
      approveDialog: false,
      rejectDialog: false,
      rejectReason: undefined,
      rejectReasonValid: true,
      renderDateTimePickerField: true,
      loading: false,
      loadingResetPassword: false,
      loadingCancelRep: false,
      loadingNewRep: false,
      search: "",
      valid: true,
      newRepdialog: false,
      resetPasswordDialog: false,
      cancelRepsDialog: false,
      enrollRejectForm: {},
      disableEnroll: true,
      headers: [
        { text: "Participant #", value: "participantKey" },
        { text: "First Name", value: "user.firstName" },
        { text: "Last Name", value: "user.lastName" },
        { text: "Email Address", value: "user.username" },
        { text: "Participant Type", value: "participantType.name" },
        { text: "Status", value: "status", align: "center" },
        { text: "Expiration Date", value: "expirationDate", align: "center" },
        { text: "Action", value: "action", sortable: false, align: "center" }
      ],
      items: [],
      itemSelected: { user: {} },
      enrollUserForm: { user: {} },
      errors: {},
      rules: {
        firstName: [v => !!v || "First Name is required"],
        lastName: [v => !!v || "Last Name is required"],
        participantType: [v => !!v || "Participant Type is required"],
        email: [v => !!v || "E-mail is required"],
        reason: [v => !!v || "Reason is required"]
      },
      total: 0,
      options: {
        itemsPerPage: 10
      },
      filterByParticipantType: {},
      cancelOption: "1"
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    onApprove(item) {
      this.approveDialog = true;
      this.itemSelected = item;
    },

    approve() {
      this.loading = true;
      let serviceCall = null;
      let postForm = {
        approvedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "APPROVAL_INDIRECT_APPROVED"
        }
      };
      serviceCall = this.$api.post("/api/participants/approve/" + this.itemSelected.id, postForm);
      serviceCall
        .then(() => {
          this.close();
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onReject(item) {
      this.rejectDialog = true;
      this.itemSelected = item;
    },

    reject() {
      this.loading = true;
      let serviceCall = null;
      let postForm = {
        reason: this.rejectReason,
        rejectedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "APPROVAL_INDIRECT_DECLINED"
        }
      };
      serviceCall = this.$api.post("/api/participants/reject/" + this.itemSelected.id, postForm);
      serviceCall
        .then(() => {
          this.close();
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClearSearch() {
      this.search = "";
      this.fetchData();
    },
    onSubmitSearch() {
      this.options.page = 0;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;

      Promise.all([
        this.participantTypeFetch().then(({ data }) => {
          this.filterByParticipantType = data.content;
          this.participantFetch();
        }),
        this.organizationGroupFetch()
      ]).finally(() => {
        this.loading = false;
      });
    },

    close() {
      this.approveDialog = false;
      this.rejectDialog = false;
      this.newRepdialog = false;
      this.resetPasswordDialog = false;
      this.cancelRepsDialog = false;
      this.enrollUserForm = { user: {} };
      this.enrollRejectForm.expirationDate = undefined;
      this.errors = {};
    },

    onSubmit() {
      this.loadingNewRep = true;
      this.errors = {};
      const participant = this.selectedParticipant;

      let registerForm = {
        participant: {
          status: { name: "ENR" },
          participantType: this.enrollUserForm.participantType,
          email1: this.enrollUserForm.user.username,
          organization: { id: participant.organization.id },
          user: this.enrollUserForm.user,
          address: {
            country: { name: participant.address.country.name }
          }
        },
        emailTemplateParticipantForm: {
          emailTemplateKey: "REGISTER_WEBSITE_SALES_REP"
        }
      };
      this.$api
        .post("/api/participants/enroll", registerForm)
        .then(() => {
          this.loadingNewRep = false;
          this.close();
          this.enrollUserForm = { user: {} };
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
          this.loadingNewRep = false;
          this.close();
        });
    },
    participantTypeFetch() {
      let fullParticipantTypeKeys = [];
      let participantTypeKeys = [];
      fullParticipantTypeKeys["directReseller"] = ["500", "510", "520", "530"];
      fullParticipantTypeKeys["directDistributor"] = ["600", "610", "620"];
      fullParticipantTypeKeys["directFulfillment"] = ["700", "710", "720", "730"];
      fullParticipantTypeKeys["indirectReseller"] = ["800", "810", "820", "830"];

      //Direct Reseller - 500 (principal) & 510 (admin)
      if (["500", "510"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["directReseller"];
      } //Direct Distributor - 600 (principal) & 610 (admin)
      else if (["600", "610"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["directDistributor"];
      } //Direct Fulfillment Reseller - 700 (principal) & 710 (admin)
      else if (["700", "710"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["directFulfillment"];
      } //Indirect (3rd Party) Reseller - 800 (principal) & 810 (admin)
      else if (["800", "810"].includes(this.selectedParticipant.participantType.participantTypeKey)) {
        participantTypeKeys = fullParticipantTypeKeys["indirectReseller"];
      }

      return this.$api.post("/api/participantTypes/search", {
        participantTypeKeys: participantTypeKeys
      });
    },
    participantFetch() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      filters.organization = { id: this.selectedParticipant.organization.id };
      filters.participantTypes = this.filterByParticipantType.map(participantType => {
        return {
          id: participantType.id
        };
      });
      filters.statuses = [{ name: "ENR" }, { name: "PRE" }];

      this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    organizationGroupFetch() {
      let unableToManageEnrollments = false;
      let serviceCall = null;
      serviceCall = this.$api.get("/api/organizations/" + this.selectedParticipant.organization.id);
      serviceCall
        .then(({ data }) => {
          this.$api.getRelatedObject("organizationGroups", data).then(({ data }) => {
            let organizationGroups = data._embedded.organizationGroups;
            organizationGroups.forEach(og => {
              if (og.organizationGroupKey == "UNABLE_TO_MANAGE_ENROLLMENTS") {
                unableToManageEnrollments = true;
              }
            });
            if (!unableToManageEnrollments) {
              this.disableEnroll = false;
            }
          });
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    openCancelRepsDialog(item) {
      this.forceRerender();
      this.itemSelected = item;
      this.cancelOption = "1";
      this.cancelRepsDialog = true;
    },
    accountCancel() {
      this.loadingCancelRep = true;
      let serviceCall = null;
      if (this.cancelOption === "1") {
        this.enrollRejectForm.expirationDate = new Date().toISOString();
        this.enrollRejectForm.emailTemplateParticipantForm = {
          emailTemplateKey: "CANCELLATION_BY_PRINCIPAL_IMMEDIATE"
        };
      } else {
        this.enrollRejectForm.emailTemplateParticipantForm = {
          emailTemplateKey: "CANCELLATION_BY_PRINCIPAL_WITH_DATE"
        };
      }
      serviceCall = this.$api.post("/api/participants/cancel/" + this.itemSelected.id, this.enrollRejectForm);
      serviceCall
        .then(() => {
          this.loadingCancelRep = false;
          this.close();
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loadingCancelRep = false;
        });
    },
    openResetPasswordDialog(item) {
      this.itemSelected = item;
      this.resetPasswordDialog = true;
    },
    resetPassword() {
      this.loadingResetPassword = true;
      let serviceCall = null;
      serviceCall = this.$api.post("/api/users/resetPassword", {
        username: this.itemSelected.user.username
      });
      serviceCall
        .then(() => {
          this.close();
          this.loadingResetPassword = false;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    openNewRepdialog() {
      this.newRepdialog = true;
    },
    getStatusColor(status) {
      switch (status) {
        case "CAN":
          return "red";
        case "ENR":
          return "primary";
        case "PRE":
          return "yellow";
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderDateTimePickerField = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderDateTimePickerField = true;
      });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Admin" },
      {
        text: "My Company Management"
      }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
